import {
  Box,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";

import t1 from "../assets/t1.png";
import t2 from "../assets/t2.jpeg";
import t3 from "../assets/t3.png";
import t4 from "../assets/t4.jpeg";
import t5 from "../assets/t5.jpeg";
import t6 from "../assets/t6.png";

import inIcon from "../assets/in.png";
import t1experience from "../assets/t1experience.png";
import t2experience from "../assets/t2experience.png";
import t3experience from "../assets/t3experience.png";

const list = [
  {
    icon: t1,
    name: "Tracy Li",
    isIn: true,
    position: "Co-Founder, Managing Partner",
    experience: t1experience,
    des: "Tracy is the founding partner of Istari Ventures with a mission to provide visionary Web3 founders with the capital, network, and long-term support needed to succeed. She leads thesis formation and due diligence for Istari Ventures. As a previous entrepreneur, she brings a founder-first mentality to investing and relationship building. In 2018 she joined Coinbase and led product development for Custody, then she went on to join PolySign, an institutional crypto custodian as Head of Product and Operations. Before founding Istari, she was a principal product manager at Circle, and served as a vital team member in building payments solutions via USDC and crypto CeFi and DeFi high-yield products. She also worked in the gaming sector at Unity.",
  },
  {
    icon: t2,
    name: "Anthony DeMartino",
    isIn: true,
    position: "General Partner",
    experience: t2experience,
    des: "With 22+ years of trading experience across 3 top-tier banks and Coinbase, Anthony focuses on diligence in DeFi, payments, and crypto market infrastructure. At UBS he ran trading desks across Fixed Income most; notably, he led the Fannie Mae/Freddie Mac desk during the Global Financial Crisis. He moved onto Barclays where he became Global Head of Emerging Markets Rates and FX trading. He ended his bank trading career at HSBC when he moved to run Latam local markets trading focusing on debt markets. In 2021 he joined Coinbase to start Coinbase Risk Strategies and lead DeFi and Derivatives Trading. The team was focused on generating yield and managing treasuries for VCs and Foundations. More recently he joined Matrixport as the US CEO and head of the Institutional business for sales, and trading.  A prior member of Coinbase Investment Committee. ",
  },
  {
    icon: t3,
    name: "Wendy Sun",
    isIn: true,
    position: "General Partner",
    experience: t3experience,
    des: (
      <>
        {" "}
        <Text>
          Wendy leads our portfolio management and fund operations. Prior to
          Istari Ventures, Wendy spent over 4 years at Goldman Sachs and
          Oppenheimer, the Investment Banking Division, advising top tier
          Technology companies on M&A, Capital Raising, Debt Issuance, and IPO.
        </Text>
        <br></br>
        <Text>
          During her banking years, She advised more than $10B transactions
          including DoorDash’s pre-IPO financing, Embracer’s $300M acquisition
          of Crystal Dynamics, SweetGreen’s IPO, etc. Most recently, she joined
          Juno Capital Partners focusing on Gaming & Digital Media investments.
        </Text>{" "}
        <br></br>
        <Text>
          Wendy has a Master of Science degree from Columbia University and
          completed her undergraduate degree at Singapore Management University
          where she was awarded the highest scholarship for 4 consecutive years.
        </Text>
      </>
    ),
  },
  {
    icon: t4,
    name: "Jeremy Zhang  ",
    isIn: false,
    position: "Partner",
    des: (
      <>
        <Text>
          Jeremy is a Partner at Istari Ventures and focuses on both the crypto
          payments sector and investor relations. He is a seasoned professional
          with 15 years of experience in Private Equity and Venture Capital.
          Most recently, Jeremy served as the Managing Partner at Ant Group,
          where he was responsible for overseeing the company’s overseas
          investments in Web3, AI, and Fintech. Prior to that, he held the
          position of Investment Director at CITIC’s $20 billion Private Equity
          Fund, where he excelled in investments across Financial Institutions,
          Software, and Technology sectors.
        </Text>{" "}
        <br></br>
        <Text>
          Jeremy has a remarkable investment track record, including investments
          such as CIIC (HR Tech, $20M, MOIC 7x), New China Life Insurance
          ($300M, MOIC 2.5x), Servyou Software (known as the Chinese Intuit,
          $100M, MOIC 2x), and Manpower Greater China ($30M, MOIC 3x).
        </Text>
        <br></br>
        <Text>
          Jeremy graduated from University of Michigan Ann Arbor and holds an
          EMBA degree from INSEAD.
        </Text>
      </>
    ),
  },
  {
    icon: t5,
    name: "Patrick McGovern ",
    isIn: true,
    position: "Chairman, Advisory Board",
    des: (
      <>
        <Text>
          Patrick is the Chairman of the advisory board at Istari Ventures. For
          25 years, as a successful entrepreneur and longtime technology
          executive Patrick has been committed to emerging tech solutions.
        </Text>{" "}
        <br></br>
        <Text>
          He is the Chair of the Investment committee, Trustee, and former the
          Chair of the Board of the Patrick J. McGovern Foundation, a $1.7B
          philanthropy focused on advancing Artificial Intelligence and data
          science to create a thriving, equitable, and sustainable future for
          all.
        </Text>
        <br></br>
        <Text>
          Patrick’s career includes serving as a board member at IDG from 2014
          to 2017, as well as holding key roles like VP of Marketing at Splunk,
          CMO at Skout, and founding Whereapp.
        </Text>
      </>
    ),
  },
  {
    icon: t6,
    name: "Arun Velagapalli, Ph.D",
    isIn: true,
    position: "Lead Researcher",
    des: (
      <>
        <Text>
          Arun leads Istari Ventures’ research initiatives with specialization
          in Zero Knowledge, Multichain, bridges, Layer 1, and Layer 2
          solutions.
        </Text>{" "}
        <br></br>
        <Text>
          Arun is currently the Principal Security Engineer & Architect at
          Robinhood Crypto. He led the development of a dedicated security team
          focused on securing crypto assets, built monitoring systems for all
          managed assets, and developed software for high-trust crypto
          operations and wallet policy engineering.
        </Text>
        <br></br>
        <Text>
          Prior to joining Robinhood, he served as the Vice President of
          Information Security at PolySign, an MPC-based crypto custodian. Arun
          holds a Ph.D. in Computer Science Engineering with a specific focus on
          Cyber Security. His educational background and work experiences have
          established him as a recognized expert in the industry.
        </Text>
      </>
    ),
  },
];

const TeamContent = () => {
  const [isPc] = useMediaQuery("(min-width: 768px)");
  return (
    <Flex
      sx={{
        width: "100%",
        padding: "45px 12px 0",
        color: "#222",
      }}
      justify={"center"}
    >
      <Flex
        sx={{
          maxWidth: "1170px",
          width: "100%",
        }}
        flexDir={"column"}
        align={"center"}
      >
        <Text
          sx={{
            width: isPc ? "100%" : "200px",
            textAlign: "center",
            fontSize: "36px",
            color: "#222",
            fontWeight: "bold",
            // paddingTop: isPc ? "0" : "80px",
          }}
        >
          Team
        </Text>
        <Box
          sx={{
            background: "rgb(34,34,34)",
            width: "100px",
            height: "5px",
            margin: "20px 0 25px",
          }}
        ></Box>
        <Text
          sx={{
            maxWidth: "750px",
            width: "100%",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          Istari Ventures’ core team consists of Web3 and traditional investment
          veterans from Coinbase, Circle, Dapper Labs, and Goldman Sachs, while
          our extended team of venture advisors and partners includes partners
          and investors from Republic Crypto, Coinbase Ventures, and Visa
          Ventures, leaders from Metamask, Robinhood Crypto, Cardano, and more.
        </Text>
        <SimpleGrid
          columns={isPc ? 3 : 1}
          spacingY={45}
          spacingX={15}
          sx={{
            width: "100%",
            marginTop: "100px",
            position: "relative",
          }}
        >
          {list.map((item, index) => {
            return (
              <Flex flexDir={"column"} key={index} align={"center"}>
                <Image
                  src={item.icon}
                  sx={{
                    width: "110px",
                  }}
                ></Image>
                <Box
                  sx={{
                    width: "0",
                    height: "2px",
                    background: "#222",
                    margin: "10px 0",
                  }}
                  className="line"
                ></Box>
                <Flex>
                  <Text
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginRight: "12px",
                    }}
                  >
                    {item.name}
                  </Text>
                  {item.isIn && (
                    <Image
                      src={inIcon}
                      sx={{
                        width: "25px",
                        height: "25px",
                      }}
                    ></Image>
                  )}
                </Flex>
                <Text
                  sx={{
                    margin: "15px 0",
                    color: "#808080",
                    fontWeight: "bold",
                  }}
                >
                  {item.position}
                </Text>
                {item.experience && (
                  <Image
                    src={item.experience}
                    sx={{
                      width: "100%",
                      height: "auto",
                    }}
                  ></Image>
                )}
                <Box
                  sx={{
                    color: "#222",
                    margin: "15px 0",
                  }}
                >
                  {item.des}
                </Box>
              </Flex>
            );
          })}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
};
export default TeamContent;
