import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  const [isPc] = useMediaQuery("(min-width: 768px)");
  return (
    <Flex
      sx={{
        padding: isPc ? "45px 0 40px 0" : "45px 20px 70px 20px",
      }}
      flexDir="column"
      align={"center"}
    >
      <Text
        sx={{
          margin: "36px 0",
          fontSize: "36px",
        }}
      >
        About US
      </Text>
      <Box
        sx={{
          background: "rgb(34,34,34)",
          width: "100px",
          height: "5px",
          margin: "20px 0 25px",
        }}
      ></Box>
      <Text
        sx={{
          maxWidth: "700px",
          textAlign: "center",
        }}
      >
        Istari Ventures is a{" "}
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          crypto payments and blockchain finance{" "}
        </span>
        focused venture fund with a mandate to invest in early-stage companies
        with proven use cases and real utilities. We strongly believe that the
        next fintech revolution is driven by the applications and infrastructure
        that will power the way we move capital and transact via blockchain. We
        are on a mission to help visionary founders to build that.
      </Text>
      <Flex
        sx={{
          width: "145px",
          height: "50px",
          border: "1px solid #fff",
          marginTop: isPc ? "30px" : "50px",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/team");
        }}
        align={"center"}
        justify={"center"}
      >
        <Text
          sx={{
            fontFamily: "Playfair Display",
          }}
        >
          Viem Team
        </Text>
        <i className="fas fa-arrow-alt-circle-right"></i>{" "}
      </Flex>
    </Flex>
  );
};
export default AboutUs;
