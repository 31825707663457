import {
  Box,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import CreditCardIcon from "../assets/credit-card.svg";
import HandshareIcon from "../assets/handshake.svg";
import LayersIcon from "../assets/layers.svg";
const list = [
  {
    icon: CreditCardIcon,
    title: "Crypto Payments",
  },
  {
    icon: HandshareIcon,
    title: "DeFi",
  },
  {
    icon: LayersIcon,
    title: "Infrastructure",
  },
];

const Investment = () => {
  const [isPc] = useMediaQuery("(min-width: 768px)");
  return (
    <Flex
      sx={{
        width: "100%",
        background: "#fff",
        padding: "45px 12px 0",
        color: "#222",
      }}
      justify={"center"}
    >
      <Flex
        sx={{
          maxWidth: "1170px",
          width: "100%",
        }}
        flexDir={"column"}
        align={"center"}
      >
        <Text
          sx={{
            width: isPc ? "100%" : "200px",
            textAlign: "center",
            fontSize: "36px",
            color: "#222",
            fontWeight: "bold",
          }}
        >
          Investment Category
        </Text>
        <Box
          sx={{
            background: "rgb(34,34,34)",
            width: "100px",
            height: "5px",
            margin: "20px 0 25px",
          }}
        ></Box>
        <Text
          sx={{
            // maxWidth: "410px",
            width: "100%",
            textAlign: "center",
            marginTop: "30px",
            fontSize: "24px",
          }}
        >
          We invest in the early-stage of the companies in seed or pre-seed
          stages.
        </Text>
        <SimpleGrid
          columns={isPc ? 3 : 1}
          sx={{
            width: "100%",
            marginTop: "100px",
            position: "relative",
          }}
        >
          {/* {isPc && (
            <>
              <Box
                sx={{
                  position: "absolute",
                  width: "1px",
                  height: "100%",
                  background: "rgba(0,0,0,0.1)",
                  left: "33.33%",
                  top: 0,
                }}
              ></Box>
              <Box
                sx={{
                  position: "absolute",
                  width: "1px",
                  height: "100%",
                  background: "rgba(0,0,0,0.1)",
                  left: "66.66%",
                  top: 0,
                }}
              ></Box>
            </>
          )} */}

          {list.map((item, index) => {
            return (
              <Flex
                flexDir={"column"}
                key={index}
                align={"center"}
                justify={"center"}
                sx={{
                  transition: "all 0.5s",
                  height: "320px",
                  cursor: "pointer",
                  borderRight:
                    isPc && index !== 2 ? "1px solid rgba(0,0,0,0.1)" : "none",
                  borderBottom:
                    !isPc && index !== 2 ? "1px solid rgba(0,0,0,0.1)" : "none",
                  "&>div": {
                    transition: "all 0.5s",
                  },
                  "&:hover": {
                    background: "rgba(0,0,0,0.05)",
                    transition: "all 0.5s",
                    "& .line": {
                      width: "100px",
                      transition: "all 0.5s",
                      margin: "40px 0",
                    },
                  },
                }}
              >
                <Image
                  src={item.icon}
                  sx={{
                    width: "48px",
                  }}
                ></Image>
                <Box
                  sx={{
                    width: "0",
                    height: "2px",
                    background: "#222",
                    margin: "10px 0",
                  }}
                  className="line"
                ></Box>
                <Text
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {item.title}
                </Text>
              </Flex>
            );
          })}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
};
export default Investment;
