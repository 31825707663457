import { Box, Flex, keyframes, useMediaQuery } from "@chakra-ui/react";
import React, { useEffect } from "react";
import VideoResource from "../assets/1.mp4";
import { motion } from "framer-motion";

const text = ["Infrastructure", "Web3", "Fintech", "DeFi"];
const widths = ["310px", "131px", "172px", "103px"];
const mwidths = ["207px", "88px", "114px", "69px"];

const VideoBg = () => {
  const [isPc] = useMediaQuery("(min-width: 768px)");
  const [reload, setReload] = React.useState(false);
  const indexRef = React.useRef(0);

  useEffect(() => {
    const timer = setInterval(() => {
      indexRef.current = indexRef.current + 1;
      setReload((prev) => !prev);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        position: "relative",
      }}
    >
      <video
        src={VideoResource}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          top: "0",
          left: "0",
        }}
        autoPlay={true}
        loop={true}
        muted={true}
      ></video>
      <Box
        sx={{
          width: "90%",
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: isPc ? "48px" : "32px",
          fontWeight: "bold",
          textAlign: "center",
          overflow: "hidden",
          verticalAlign: "bottom",
        }}
      >
        <span
          style={{
            lineHeight: "1.2em",
          }}
        >
          Investing in the future of
          {/* {text[indexRef.current % text.length]} */}
        </span>
        <motion.span
          key={1}
          style={{
            // width: "100%",
            display: "inline-block",
            wordBreak: "break-all",
            overflow: "hidden",
            maxHeight: "1.2em",
            // lineHeight: "48px",
            marginLeft: isPc ? "14px" : "8px",
            verticalAlign: "top",
          }}
          transition={{
            duration: 2,
            ease: "linear",
            repeat: Infinity,
          }}
          animate={{
            width: [
              "10px",
              isPc
                ? widths[indexRef.current % text.length]
                : mwidths[indexRef.current % text.length],
            ],
            transition: {
              duration: 1,
              repeat: Infinity,
              repeatType: "loop",
              repeatDelay: 2,
            },
          }}
        >
          {text[indexRef.current % text.length]}
        </motion.span>
      </Box>
    </Box>
  );
};
export default VideoBg;
