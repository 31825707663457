import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Logo from "../assets/logo.png";
import MLogo from "../assets/m-logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "../assets/menu.svg";

const menus = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "Team",
    link: "/team",
  },
  {
    label: "Portfolio",
    link: "/",
    value: "portfolio",
  },

  {
    label: "Insights",
    link: "/",
    link2: "https://medium.com/@Istari_Ventures",
  },

  // {
  //   label: "Value Add",
  //   link: "/valueAdd",
  // },
];

const MHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          height: "100px",
          background: "#191919",
          width: "100%",
          position: "relative",
          zIndex: "14001",
        }}
      >
        <Image
          src={MenuIcon}
          ref={btnRef}
          onClick={onOpen}
          sx={{
            width: "32px",
            height: "32px",
            position: "absolute",
            top: "50%",
            left: "36px",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
        ></Image>
        <Image
          src={MLogo}
          sx={{
            width: "60px",
            height: "60px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        ></Image>
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          sx={{
            background: "#fff",
            paddingTop: "100px",
            paddingLeft: "30px",
          }}
        >
          <DrawerBody
            sx={{
              color: "#222",
              padding: "0",
            }}
          >
            {menus.map((item, index) => {
              return (
                <Box
                  onClick={() => {
                    if (item.link2) {
                      window.open(item.link2, "_blank");
                      return;
                    }
                    if (item.value) {
                      const aa = document.getElementById(item.value);
                      console.log(aa, "aa");
                      document.getElementById(item.value).scrollIntoView();
                      return;
                    }
                    navigate(item.link);
                  }}
                  key={index}
                  sx={{
                    height: "60px",
                    lineHeight: "60px",
                    borderBottom: "1px solid rgb(238, 238, 238)",
                    "&:hover": {
                      color: "rgb(239, 181, 136)",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Text>{item.label.toLocaleUpperCase()}</Text>
                </Box>
              );
            })}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const Header = () => {
  const [isPc] = useMediaQuery("(min-width: 768px)");
  const [isHome, setIsHome] = React.useState(false);
  const navigate = useNavigate();

  const location = useLocation();

  const { pathname } = location;

  useEffect(() => {
    if (pathname === "/") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [pathname]);

  return isPc ? (
    <Box
      sx={{
        width: "100%",
        height: "100px",
        position: "absolute",
        top: "0",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: "999",
        padding: "0 36px",
        background: isHome ? "transparent" : "#191919",
      }}
    >
      <Flex
        sx={{
          maxWidth: "1170px",
          height: "100%",
          margin: "0 auto",
        }}
        align={"center"}
        justify={"space-between"}
      >
        <Image
          src={Logo}
          sx={{
            width: "133px",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/");
          }}
        ></Image>
        <Flex>
          {menus.map((menu, index) => {
            return (
              <Text
                sx={{
                  marginLeft: "20px",
                  fontSize: "16px",
                  "&:hover": {
                    color: "rgb(239, 181, 136)",
                    cursor: "pointer",
                  },
                }}
                key={index}
                onClick={() => {
                  if (menu.link2) {
                    window.open(menu.link2, "_blank");
                    return;
                  }
                  if (menu.value) {
                    const aa = document.getElementById(menu.value);
                    console.log(aa, "aa");
                    document.getElementById(menu.value).scrollIntoView();
                    return;
                  }
                  navigate(menu.link);
                }}
              >
                {menu.label}
              </Text>
            );
          })}
        </Flex>
      </Flex>
    </Box>
  ) : (
    <MHeader></MHeader>
  );
};
export default Header;
