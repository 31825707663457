import { Box, Flex, GridItem, SimpleGrid, Text, Image } from "@chakra-ui/react";
import React from "react";

const Partner = () => {
  return (
    <Flex
      sx={{
        background: "rgb(2,5,5)",
        padding: "45px 0 48px",
        width: "100%",
      }}
      flexDir={"column"}
      align={"center"}
    >
      <Text
        sx={{
          fontSize: "36px",
        }}
      >
        Our Partner
      </Text>{" "}
      <Box
        sx={{
          background: "rgb(34,34,34)",
          width: "100px",
          height: "5px",
          margin: "20px 0 25px",
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "1140px",
          fontSize: "18px",
          padding: "0 15px 0",
          "& a": {
            cursor: "pointer",
            textDecoration: "underline",
            color: "#c2c2c2",
            fontSize: "15px",
          },
        }}
      >
        <Text>Crypto Accounting</Text>
        <Box sx={{ margin: "15px 0" }}>
          <a href="https://www.propellerindustries.com/" target="_blank">
            Propeller Industries{" "}
          </a>{" "}
        </Box>

        <Text>Node Validator</Text>
        <Box sx={{ margin: "15px 0" }}>
          <a href="https://www.kysenpool.io/en" target="_blank">
            KysenPool
          </a>{" "}
        </Box>
      </Box>
    </Flex>
  );
};
export default Partner;
