import React, { ReactElement, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import App from "./App";
import Activities from "./pages/Activities";
import Team from "./pages/Team";

const AppRoutes = (): ReactElement => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<Activities />} />
        <Route path="/team" element={<Team />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
