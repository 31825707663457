import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import P1 from "../assets/p1.jpeg";
import P2 from "../assets/p2.png";
import P3 from "../assets/p3.png";
import P4 from "../assets/p4.png";
import P5 from "../assets/p5.png";
import P6 from "../assets/p6.png";
import P7 from "../assets/p7.png";
import P8 from "../assets/p8.png";
import P9 from "../assets/p9.png";
import P10 from "../assets/p10.png";
import P11 from "../assets/p11.png";
import P12 from "../assets/p12.png";
import Pfuzz from "../assets/fuzz.webp";
import PNex from "../assets/nex.png";
import Pvic from "../assets/vic.png";
import Quipu from "../assets/quipu.png";
import Bit from "../assets/bit.jpeg";
import Taka from "../assets/taka.png";
import Magic from "../assets/magic.png";
import Moonwell from "../assets/moonwell.png";
import Noramp from "../assets/noramp.png";
import ArrowIcon from "../assets/right-arrow.svg";
import LinkIcon from "../assets/link.svg";

import { motion } from "framer-motion";

const list = [
  {
    img: P12,
    action: "go",
    text: "Transak – Easy on-ramp across 150+ countries",
    background: "#fff",
    height: "80%",
    width: "80%",
  },
  {
    img: Quipu,
    action: "copy",
    text: "",
    link: "https://www.cbinsights.com/company/quipu-market",
    background: "#000",
    height: "80%",
    width: "80%",
  },
  {
    img: P5,
    action: "copy",
    text: "CyberConnect – Social graph protocol",
    link: "https://cyberconnect.me/",
    background: "#000",
    height: "80%",
    width: "80%",
  },
  {
    img: P4,
    action: "copy",
    text: "Coin98 – all in one wallet",
    link: "https://coin98.com/",
  },
  {
    img: PNex,
    action: "copy",
    text: "The best search and exploration experience for transaction data",
    link: "https://www.nexandria.com/",
    background: "#fff",
    height: "50%",
    width: "50%",
  },
  {
    img: Pfuzz,
    action: "copy",
    text: "Regulated B2B2C digital asset infrastructure",
    link: "https://www.fuze.finance",
    background: "rgb(31,31,45)",
    height: "50%",
    width: "50%",
  },
  {
    img: P1,
    action: "go",
    text: "Aura Network – Cosmos based Layer-1",
  },

  {
    img: P2,
    action: "copy",
    text: "C14 – Easy on-and-off ramps",
    link: "https://www.c14.money/",
  },
  // {
  //   img: P3,
  //   action: "copy",
  //   text: "Coherent – Data indexing protocol",
  //   link: "https://coherent.sh/",
  // },

  // {
  //   img: P6,
  //   action: "copy",
  //   text: "In the Meta",
  //   link: "https://inthemeta.io/",
  // },
  {
    img: P7,
    action: "copy",
    text: "Latamex – Latin America on-and-off ramps",
    link: "https://istari.ventures/?portfolio=latamex",
    background: "#fff",
    height: "80%",
    width: "80%",
  },
  {
    img: P8,
    action: "copy",
    text: "Parallel – DeFi",
    link: "https://parallel.life/",
  },

  {
    img: P9,
    action: "go",
    text: "Ping – Easy crypto payments solution",
    link: "https://istari.ventures/?portfolio=ping",
    height: "50%",
    width: "50%",
    background: "#fff",
  },
  {
    img: P10,
    action: "copy",
    text: "Sommelier Finance – DeFi protocol",
    link: "https://www.sommelier.finance/",
  },
  {
    img: P11,
    action: "copy",
    text: "  Summoners Arena – Play-own-earn multi-genre game",
    link: "https://summonersarena.io/",
  },

  {
    img: Pvic,
    action: "go",
    text: "EVM compatible L1 that provides zero gas fees and heighted security",
    link: "https://www.techinasia.com/tomochains-rebrand-viction-paves-victory-vision",
    background: "#fff",
    height: "50%",
    width: "50%",
  },
  {
    img: Bit,
    action: "go",
    text: "The #1 Digital Asset Finance Platform For Enterprise.",
    link: "https://www.bitwave.io/",
    background: "#fff",
    height: "50%",
    width: "50%",
  },
  {
    img: Taka,
    action: "go",
    text: "Takadao is igniting a global movement against the broken financial system. A movement driven by mutual consent and agreement, democratically. Where stakeholders are decision makers.",
    link: "https://takadao.io/",
    background: "#fff",
    height: "70%",
    width: "70%",
  },
  {
    img: Magic,
    action: "go",
    text: "",
    link: "https://magic.link",
    background: "#fff",
    height: "70%",
    width: "70%",
  },
  {
    img: Moonwell,
    action: "go",
    text: "",
    link: "https://moonwell.fi",
    background: "#fff",
    height: "70%",
    width: "70%",
  },
  {
    img: Noramp,
    action: "go",
    text: "",
    link: "https://noramp.io",
    background: "#fff",
    height: "70%",
    width: "70%",
  },
];

const Item = ({
  detail,
  onClick,
  isClick,
}: {
  detail: any;
  onClick: () => void;
  isClick: boolean;
}) => {
  const [isHover, setIsHover] = React.useState(false);

  useEffect(() => {
    if (isClick) {
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  }, [isClick]);

  return (
    <motion.div
      style={{
        width: "100%",
        cursor: "pointer",
        position: "relative",
        overflow: "hidden",
        // aspectRatio: 16 / 9,
        paddingBottom: "56%",
      }}
      id={"portfolio"}
      className="test1"
      onClick={() => {
        onClick();
      }}
      onHoverStart={() => {
        onClick();
      }}
      onHoverEnd={() => setIsHover(false)}
    >
      <Box
        sx={{
          background: isHover ? "rgb(238,178,131,0.9)" : "rgb(238,178,131,0)",
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          transition: "all 0.3s",
          zIndex: 10,
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (detail.action === "go") {
            // window.open("https://www.google.com");
          } else {
            window.open(detail.link, "_blank");
          }
        }}
      >
        <motion.div
          style={{
            position: "absolute",
            top: "20%",
            left: "50%",
            transform: "translateX(-50%)",
            background: "#fff",
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          animate={{
            top: isHover ? "26%" : "20%",
            opacity: isHover ? 1 : 0,
          }}
        >
          <Image
            style={{
              width: "36px",
            }}
            src={detail.action === "go" ? ArrowIcon : LinkIcon}
          ></Image>
        </motion.div>

        <motion.div
          style={{
            fontSize: "18px",
            position: "absolute",
            top: "80%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            textAlign: "center",
          }}
          animate={{
            top: isHover ? "70%" : "80%",
            opacity: isHover ? 1 : 0,
          }}
        >
          <Text>{detail.text}</Text>
        </motion.div>
      </Box>

      <Flex
        sx={{
          width: "100%",
          borderRadius: "6px",
          height: "100%",
          background: detail?.background,
          alignItems: "center",
          justifyContent: "center",
          aspectRatio: 16 / 9,
          position: "absolute",
          left: "0",
          top: 0,
        }}
      >
        <Image
          src={detail.img}
          objectFit="contain"
          sx={{
            width: detail?.width ? detail?.width : "100%",
            height: detail?.height ? detail?.height : "100%",
            borderRadius: "6px",
          }}
        ></Image>
      </Flex>
    </motion.div>
  );
};

const Portfolio = () => {
  const [isPc] = useMediaQuery("(min-width: 768px)");
  const [clickIndex, setClickIndex] = React.useState(-1);
  return (
    <Flex
      sx={{
        background: "rgb(5,7,7)",
        padding: "45px 30px 0",
        width: "100%",
      }}
      flexDir={"column"}
      align={"center"}
    >
      <Flex align={"center"}>
        <Text
          sx={{
            fontSize: "36px",
            marginRight: "10px",
          }}
        >
          Portfolio
        </Text>
      </Flex>
      <Box
        sx={{
          background: "rgb(34,34,34)",
          width: "100px",
          height: "5px",
          margin: "20px 0 25px",
        }}
      ></Box>
      <SimpleGrid
        columns={isPc ? 3 : 1}
        spacingX={8}
        spacingY={8}
        sx={{
          width: "100%",
        }}
      >
        {list.map((item, index) => {
          return (
            <Item
              detail={item}
              key={index}
              isClick={clickIndex === index}
              onClick={() => {
                setClickIndex(index);
              }}
            ></Item>
          );
        })}
      </SimpleGrid>
    </Flex>
  );
};
export default Portfolio;
