import { Box, Text } from "@chakra-ui/react";
import React from "react";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        borderTop: "1px solid rgb(238,177,131)",
      }}
    >
      <Box
        sx={{
          maxWidth: "1140px",
          padding: "40px 15px 30px",
          margin: "0 auto",
          color: "#9e9e9e",
          fontSize: "15px",
        }}
      >
        <Text>© 2024 Istari Ventures</Text>
      </Box>
    </Box>
  );
};

export default Footer;
