import {
  Box,
  Flex,
  GridItem,
  SimpleGrid,
  Text,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";

import a1 from "../assets/a1.png";
import a2 from "../assets/a2.png";
import a3 from "../assets/a3.png";
import a4 from "../assets/a4.png";
import a5 from "../assets/a5.png";
import a6 from "../assets/a6.jpeg";
import a7 from "../assets/a7.jpeg";
import a8 from "../assets/a8.png";
import a9 from "../assets/a9.png";
import a10 from "../assets/a10.jpeg";
import a11 from "../assets/a11.png";
import a12 from "../assets/a12.png";
import a13 from "../assets/a13.png";
import a14 from "../assets/a14.png";
// experience
import inIcon from "../assets/in.png";
import a1experience from "../assets/a1experience.png";
import a2experience from "../assets/a2experience.png";
import a3experience from "../assets/a3experience.png";
import a4experience from "../assets/a4experience.png";
import a5experience from "../assets/a5experience.png";
import a6experience from "../assets/a6experience.png";
import a7experience from "../assets/a7experience.png";
import a8experience from "../assets/a8experience.png";
import a9experience from "../assets/a9experience.png";
import a10experience from "../assets/a10experience.png";
import a11experience from "../assets/a11experience.png";
import a12experience from "../assets/a12experience.png";

const list = [
  {
    icon: a1,
    name: "Carl Cortright",
    isIn: true,
    position: "Co-Founder & CEO at Coherent",
    experience: a1experience,
  },
  {
    icon: a2,
    name: "Luke Youngblood ",
    isIn: true,
    position: "Co-Founder & CEO at Moonwell",
    experience: a2experience,
  },
  {
    icon: a3,
    name: "Alan Chiu",
    isIn: true,
    position: "Co-Founder & CEO at Boba Network",
    experience: a3experience,
  },
  {
    icon: a4,
    name: "Thanh Le ",
    isIn: true,
    position: "Founder & CEO, Coin98",
    experience: a4experience,
  },
  {
    icon: a5,
    name: "Shailee Adinolfi",
    isIn: true,
    position: "Head of Partnership, Metamask",
    experience: a5experience,
  },
  {
    icon: a6,
    name: "Weili Geng",
    isIn: true,
    position: "Product Lead, MoonPay",
    experience: a6experience,
  },
  {
    icon: a7,
    name: "Alan Leung",
    isIn: true,
    position: "CISO, Coinbase Custody",
    experience: a7experience,
  },
  {
    icon: a8,
    name: "Damon Burk",
    isIn: true,
    position: "BD, Binance LATAM",
    experience: a8experience,
  },
  {
    icon: a9,
    name: "Arun Velagaphalli, Ph.D.",
    isIn: true,
    position: "Principle Security Arch, Robinhood Crypto",
    experience: a9experience,
  },
  {
    icon: a10,
    name: "Jack Chan",
    isIn: true,
    position: "Investor, Harmony Ventures",
    experience: a10experience,
  },
  {
    icon: a11,
    name: "Giang Tran",
    isIn: true,
    position: "Co-Founder & CEO, Aura Network",
    experience: a11experience,
  },
  {
    icon: a12,
    name: "Dan Smith",
    isIn: true,
    position: "Lead Product Designer",
    experience: a12experience,
  },
  {
    icon: a13,
    name: "Armando Aguilar",
    isIn: true,
    position: "Investor at Lightshift Capital",
  },
  {
    icon: a14,
    name: "Jon Martin",
    isIn: true,
    position: "Product Lead, Mythical Games",
  },
];

const Advisors = () => {
  const [isPc] = useMediaQuery("(min-width: 768px)");
  return (
    <Flex
      sx={{
        width: "100%",
        background: "#fff",
        padding: "45px 12px 60px",
        color: "#222",
      }}
      justify={"center"}
    >
      <Flex
        sx={{
          maxWidth: "1170px",
          width: "100%",
        }}
        flexDir={"column"}
        align={"center"}
      >
        <Text
          sx={{
            width: isPc ? "100%" : "200px",
            textAlign: "center",
            fontSize: "36px",
            color: "#222",
            fontWeight: "bold",
          }}
        >
          Advisors
        </Text>
        <Box
          sx={{
            background: "rgb(34,34,34)",
            width: "100px",
            height: "5px",
            margin: "20px 0 25px",
          }}
        ></Box>

        <SimpleGrid
          columns={isPc ? 3 : 1}
          spacingY={45}
          spacingX={15}
          sx={{
            width: "100%",
            marginTop: "100px",
            position: "relative",
          }}
        >
          {list.map((item, index) => {
            return (
              <Flex flexDir={"column"} key={index} align={"center"}>
                <Image
                  src={item.icon}
                  sx={{
                    width: "110px",
                  }}
                ></Image>
                <Box
                  sx={{
                    width: "0",
                    height: "2px",
                    background: "#222",
                    margin: "10px 0",
                  }}
                  className="line"
                ></Box>
                <Flex>
                  <Text
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      marginRight: "12px",
                    }}
                  >
                    {item.name}
                  </Text>
                  {item.isIn && (
                    <Image
                      src={inIcon}
                      sx={{
                        width: "25px",
                        height: "25px",
                      }}
                    ></Image>
                  )}
                </Flex>
                <Text
                  sx={{
                    margin: "15px 0",
                    color: "#808080",
                  }}
                >
                  {item.position}
                </Text>
                {item.experience && (
                  <Image
                    src={item.experience}
                    sx={{
                      height: "30px",
                    }}
                  ></Image>
                )}
              </Flex>
            );
          })}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
};
export default Advisors;
