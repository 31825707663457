import "swiper/css/bundle";
import { Box } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Footer from "./components/Footer";

const App = (): ReactElement => {
  return (
    <Box
      minH="100vh"
      bg="black"
      color="white"
      sx={{
        fontFamily: "Montserrat",
      }}
    >
      <Header></Header>
      <Outlet />
      <Footer></Footer>
      <ToastContainer></ToastContainer>
    </Box>
  );
};

export default App;
