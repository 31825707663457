import AboutUs from "@/components/AboutUs";
import Advisors from "@/components/Advisors";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Investment from "@/components/Investment";
import Partner from "@/components/Partner";
import Portfolio from "@/components/Portfolio";
import TeamContent from "@/components/TeamContent";
import VideoBg from "@/components/VideoBg";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { ReactElement, useEffect } from "react";

const Team = (): ReactElement => {
  const [isLargerThan800] = useMediaQuery("(min-width: 1200px)");

  const changeLanguage = (language: string) => {
    localStorage.setItem("language", language);
  };

  return (
    <Flex
      w="100%"
      flexDir={"column"}
      align={"center"}
      sx={{
        background: "#fff",

        minHeight: "100vh",
        paddingTop: "100px",
      }}
    >
      <TeamContent></TeamContent>
      <Advisors></Advisors>
    </Flex>
  );
};

export default Team;
